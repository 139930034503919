<template>
  <b-container class="page-container">
    <Breadcrumbs currentpage="Activatie van je e-mailadres" />
    <b-alert v-if="invalidLink || !code" show variant="warning"
      ><svg
        aria-hidden="true"
        focusable="false"
        data-prefix="fas"
        data-icon="exclamation-circle"
        role="img"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="svg-inline--fa fa-exclamation-circle fa-w-16 fa-5x"
      >
        <path
          fill="currentColor"
          d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zm-248 50c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
          class=""
        ></path>
      </svg>
      {{ $t('activation-emailadres') }}<br />

      {{ $t('account-created-page-description1') }}

    </b-alert>
    <b-row v-if="!invalidLink && code && doneLoading && !canLogin">
      <b-col xs="12" md="12">
        <h1 class="pb-3 text-center">
          {{ $t('confirmation-of-your-emailadres') }}
        </h1>
        <p>
          {{ $t('your-email-succesfully-confirmed') }}
        </p>
      </b-col>
    </b-row>
    <b-row v-if="!invalidLink && code && doneLoading && canLogin">
      <b-col xs="12" md="12">
        <h1 class="pb-3 text-center">
          {{ $t('confirmation-of-your-emailadres') }}
        </h1>
        <h4 class="pb-3">{{ $t('thanks-for-verification-emailadres') }}!</h4>
        <p>{{ $t('emailadres-succesfully-confirmed-login') }}.</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
const { confirmUser } = require('@/services/AuthorizationService')
import Breadcrumbs from '@/components/header/BreadCrumbs'
export default {
  components: { Breadcrumbs },
  data() {
    return {
      invalidLink: false,
      doneLoading: false,
      canLogin: false
    }
  },
  computed: {
    code: function() {
      return this.$route.params.code || false
    }
  },
  mounted: async function() {
    const result = await confirmUser({ code: this.code })
    if (result.Message == 'InvalidModelState') {
      this.invalidLink = true
    }

    if (result.data == 'Exception_RegistrationConfirmWrongInput') {
      this.invalidLink = true
    }

    if (result.data === 'Succes_RegistrationAndUserConfirm') {
      this.canLogin = true
    }

    if (result.data === '') {
      this.invalidLink = true
    }

    this.doneLoading = true
  }
}
</script>

<style scoped lang="scss">
.page-container {
  h4 {
    font-size: 35px;
  }
  p {
    font-size: 18px;
  }
}
</style>
